import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css";
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import {AppHome} from './App';
import reportWebVitals from './reportWebVitals';
import { LucasApp } from './Paginas/Lucas';
import { CarlaApp } from './Paginas/Carla';
import { YouTubeApp } from './Paginas/YouTube';
import { InstagramApp } from './Paginas/Instagram';
import { TwitterApp } from './Paginas/Twitter';

const router = createBrowserRouter([
  { path: "/", element: <AppHome /> },
  { path: "/Lucas", element: <LucasApp /> },
  { path: "/Carla", element: <CarlaApp /> },
  { path: "/YouTube", element: <YouTubeApp /> },
  { path: "/Ig", element: <InstagramApp /> },
  { path: "/Insta", element: <InstagramApp /> },
  { path: "/Instagram", element: <InstagramApp /> },
  { path: "/twitter", element: <TwitterApp /> },
])



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
