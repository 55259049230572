import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const TwitterApp = () => {

  const history = useNavigate();

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      window.location.href = 'https://twitter.com/LunaticProject_';
    }, 0);

    return () => clearTimeout(redirectTimeout);
  }, [history]);

  return (<></>);
}
